<template>
	<div class="createParks">
		<div class="main-content-title">
			<p>共有 {{ total }} 条结果</p>
			<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
		</div>
		<Table
			:loading="loading"
			:table-data="tableData"
			:total="total"
			:table-head="tableHead"
			:current-page.sync="queryData.current"
			:page-size.sync="queryData.size"
			:is-show-selection="false"
			:operation-column-width="220"
			:showOperation="true"
			@change-page="getList"
			:tableHeight="tableHeight"
		>
			<template #file="{ scope }">
				<el-button @click="showList(scope.row)" size="mini" type="text"> 查看({{ (scope.row.file && scope.row.file.length) || 0 }}) </el-button>
			</template>
			<template #enabled="{ scope }">
				<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
			</template>
			<template #operation="{ scope }">
				<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
				<el-button
					@click="modifyParkInfo($event, false, scope.row)"
					size="mini"
					:type="scope.row.enabled ? 'info' : 'warning'"
					:disabled="scope.row.enabled"
				>
					修改
				</el-button>
				<el-button @click="uploadFiles(scope.row)" size="mini" type="info" class="uploadBtn"> 上传材料 </el-button>
			</template>
		</Table>

		<createView ref="createView" @getList="getList" :isShowDetail="isShowDetail" />
		<UploadFiles ref="uploadFiles" @getList="getList" />
		<ShowFiles ref="showFileList" @getList="getList"></ShowFiles>
	</div>
</template>

<script>
export default {
	name: 'addCourse',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
		UploadFiles: () => import('./uploadFiles.vue'),
		ShowFiles: () => import('./showFileList.vue'),
	},
	data() {
		return {
			loading: false,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '课程名称',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '课程类型',
					prop: 'contentTypeDictionaryItem',
					formatter: (row) => {
						return row.contentTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '课程编码',
					prop: 'serialNumber',
					formatter: (row) => {
						return row.serialNumber || '-';
					},
				},
				{
					label: '所属科目',
					prop: 'lessonTypeDictionaryItem',
					formatter: (row) => {
						return row.lessonTypeDictionaryItem?.name || '-';
					},
				},
				{
					label: '材料附件',
					prop: 'file',
					width: 130,
				},
				{
					label: '课程定价',
					prop: 'price',
					formatter: (row) => {
						return row.price / 100 + '元' || '-';
					},
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			link: '',
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 550;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		//获取列表
		getList() {
			if (!this.$route.query.id) return;
			this.loading = true;
			let data = {
				...this.queryData,
				famousTeacherLectureId: this.$route.query.id,
			};
			this.$http
				.get(this.api.getLectureLessons, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.link = res.data._links.create.href || '';
						res.data.collection.forEach((item) => {
							item.file = [];
							if (!item.attachmentMap) return;
							if (item.attachmentMap['DOCUMENT'] && !item.attachmentMap['VIDEO']) {
								item.file = [...item.attachmentMap['DOCUMENT']];
							} else if (item.attachmentMap['VIDEO'] && !item.attachmentMap['DOCUMENT']) {
								item.file = [...item.attachmentMap['VIDEO']];
							} else if (!item.attachmentMap['DOCUMENT'] && !item.attachmentMap['VIDEO']) {
								item.file = [];
							} else {
								item.file = [...item.attachmentMap['DOCUMENT'], ...item.attachmentMap['VIDEO']];
							}
						});
						this.tableData = res.data.collection || [];
						this.total = res.data.pagination.totalItems || 0;
						this.loading = false;
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
		uploadFiles(row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			this.$refs.uploadFiles.init(data);
		},
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		showList(row) {
			this.$refs.showFileList.init(row);
			// this.$refs.showFileList.tableData = row.file || [];
		},
	},
};
</script>

<style lang="scss" scoped>
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.uploadBtn {
	background-color: #38b4f2 !important;
	border-color: #38b4f2 !important;
}
</style>
